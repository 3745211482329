
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
import { NoAccessTypes } from '@/ConstValues';
@Component({
  components: { DefaultLayout }
})
export default class NotConfirmed extends Vue {
  $refs!: {
    DefaultActions: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  noAccessType: NoAccessTypes | null = null;
  noAccessMessage: string = '';
  /* Utility Functions */
  buildNoAccessDisplay() {
    switch (this.noAccessType) {
      case NoAccessTypes.Disabled: {
        this.noAccessMessage =
          'It looks like your account is disabled! Please contact techsupport@just1label.com to gain access to your account.';
        return;
      }
      case NoAccessTypes.CreditHold: {
        this.noAccessMessage =
          'It looks like your account is on credit hold! Please contact accounting@just1label.com to gain access to your account.';
        return;
      }
      default: {
        this.noAccessMessage =
          'It looks like you account is not confirmed! Please check the email address associated with your account to confirm. If you did not get an email, please reach out to techsupport@just1label.com to get everything situated';
        return;
      }
    }
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    this.noAccessType = Number(this.$route.params.type) as NoAccessTypes;
    console.log(this.$route.params.type);
    this.buildNoAccessDisplay();
  }
  /* Created */
  /* Emmited Functions */
}
